<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs v-model="activeTab">
            <v-tab>{{$t('settings.kassendrucker')}}</v-tab>
            <v-tab>{{$t('settings.langOrderbonPrinter')}}</v-tab>
            <v-tab>{{$t('settings.lang_labelPrinter')}}</v-tab>
            <v-tab>{{$t('settings.lang_virtualPrinter')}}</v-tab>
          </v-tabs>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" lg="6" md="12">
                  <!-- Name -->
                  <v-text-field v-model="name"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                autocomplete="off"
                                :label="$t('settings.lang_printerName')"
                                outlined
                                required
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                  <!--group by room or cashier id -->
                  <v-radio-group v-model="group_by" :label="$t('generic.lang_groupBy')" v-if="this.activeTab === 3">
                    <v-radio
                        :label="$t('erp.lang_room')"
                        :value="1"
                    ></v-radio>

                    <v-radio
                        :label="$t('generic.lang_cashierID')"
                        :value="2"
                    ></v-radio>
                  </v-radio-group>

                  <!-- Gateway -->
                  <v-radio-group v-if="this.activeTab !== 3" v-model="gateway" :label="$t('settings.lang_printerGateway')">
                    <v-radio :label="$t('settings.lang_bluetooth_onlyInTheApp')" value="bluetoothApp"></v-radio>
                    <v-radio :label="'EPSON ePOS(' + $t('settings.lang_network') + ')'" value="epos"></v-radio>
                    <v-radio label="ESC/POS" value="escpos"></v-radio>
                    <v-radio :label="'ESC/POS ' + $t('settings.lang_external')" value="escposexternal"></v-radio>
                  </v-radio-group>

                  <!-- IP Adresse -->
                  <v-text-field v-if="gateway === 'epos' && this.activeTab !== 3"
                                v-model="address"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                autocomplete="off"
                                :label="$t('settings.lang_printerManagerIPAdress')"
                                outlined
                                required
                                @focus="showTouchKeyboard"
                  ></v-text-field>

                  <template v-if="gateway === 'escpos'">
                    <!-- IP Adresse -->
                    <v-text-field
                        v-model="address"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        autocomplete="off"
                        :label="$t('settings.lang_printerManagerIPAdress')"
                        outlined
                        placeholder="192.168.178.45"
                        required
                        @focus="showTouchKeyboard"
                    ></v-text-field>
                  </template>
                  <template v-if="gateway === 'escposexternal'">

                    <v-radio-group v-model="externalType"
                                   :label="$t('settings.lang_typeOfExternalESCPosPrinter')" row>
                      <v-radio :label="$t('settings.lang_network')" value="network"></v-radio>
                      <v-radio label="USB" value="usb"></v-radio>
                    </v-radio-group>
                    <v-row v-if="externalType==='usb'">
                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            class="mx-0"
                            v-model="usbField1"
                            autocomplete="off"
                            label="USB HID 1"
                            outlined
                            required
                            hide-details
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            @focus="showTouchKeyboard"

                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            class="mx-0"
                            v-model="usbField2"
                            autocomplete="off"
                            label="USB HID 2"
                            outlined
                            required
                            hide-details
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            @focus="showTouchKeyboard"

                        ></v-text-field><br><br>
                      </v-col>
                    </v-row>
                    <!-- IP Adresse -->
                    <v-text-field v-if="externalType==='network'"
                        v-model="address"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        autocomplete="off"
                        :label="$t('settings.lang_printerManagerIPAdress')"
                        outlined
                        placeholder="192.168.178.45"
                        required
                        @focus="showTouchKeyboard"
                    ></v-text-field>
                    <!-- IP Adresse -->
                    <v-text-field
                        v-model="serverAddress"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        autocomplete="off"
                        :label="$t('settings.lang_printServerIPAddress')"
                        outlined
                        hide-details
                        placeholder="127.0.0.1"
                        required
                        @focus="showTouchKeyboard"
                    ></v-text-field>
                  </template>

                  <!-- PapierTyp -->
                  <v-radio-group v-if="activeTab === 0 || activeTab === 1" v-model="paperType" :label="$t('settings.lang_printerManagerPaperType')">
                    <v-radio :value="80" label="80mm"></v-radio>
                    <v-radio :value="50" label="50mm"></v-radio>
                  </v-radio-group>

                  <!-- KassenID -->
                  <v-select
                      v-if="activeTab === 0"
                      v-model="cashierID"
                      :items="filteredCashierIDs"
                      :label="$t('generic.lang_cashierID')"
                      multiple
                      outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" lg="6" md="12">
                  <!-- KassenDrucker -->
                  <div v-if="activeTab === 0">
                    <v-checkbox v-model="openCashDrawer" :false-value="0" :true-value="1"
                                :label="$t('settings.lang_printerManagerOpenDrawerCardPayment')"
                                style="margin-top: 0;"></v-checkbox>
                  </div>
                  <div>
                    <v-checkbox v-model="autoPrint" :false-value="0" :true-value="1" :label="$t('generic.lang_autoPrint')"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="useSSL" :false-value="0" :true-value="1" :label="$t('generic.lang_SecureConnection')"
                                style="margin-top: 0;"></v-checkbox>
                  </div>
                  <!-- OrderBon Drucker -->
                  <div v-if="activeTab === 1 || this.activeTab === 3">
                    <v-checkbox v-model="lineBreak" :false-value="0"
                                :label="this.$t('settings.lang_printerManagerTrimWarename')" :true-value="1"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="autoPaperCut" :false-value="0"
                                :label="this.$t('settings.lang_printerManagerCutAfterEachPosition')" :true-value="1"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="showItemPrices" :false-value="0"
                                :label="this.$t('settings.lang_printerManagerDisplayPricesOrderbon')" :true-value="1"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="showPLU" :false-value="0"
                                :label="this.$t('settings.lang_printerManagerDisplayPLUonOrderbon')" :true-value="1"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="hideItemNames" :false-value="0"
                                :label="this.$t('settings.lang_printerManagerHideWareName')" :true-value="1"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="coursesOnOneBon" :false-value="0"
                                :label="this.$t('settings.lang_printCoursesOnOneOrderbon')" :true-value="1"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="duplicateOrderBonPrint" :false-value="0" :true-value="1"
                                :label="$t('settings.lang_printOrderbonTwice')"
                                style="margin-top: 0;"></v-checkbox>
                    <v-checkbox v-model="playBuzzer" :false-value="0" :true-value="1"
                                :label="$t('settings.lang_playBuzzerSound')"
                                style="margin-top: 0;"></v-checkbox>

                  </div>
                </v-col>


              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading || name.length === 0" :loading="loading" color="success" @click="createPrinter">
              {{ $t('settings.lang_createPrinter') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Warning Dialog -->
    <v-dialog :value="errorDialogMessage.length > 0" max-width="300">
      <v-card>
        <v-card-title>{{ $t('generic.lang_warning') }}</v-card-title>
        <v-card-text>
          {{ errorDialogMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialogMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-container>
</template>

<script>
import mixin from "../../../../mixins/KeyboardMixIns";
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";

export default {
  name: "CreatePrinter",

  mixins: [mixin],

  computed: {
    ...mapState([
      'api'
    ]),
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierID) => {
        return {
          value: parseInt(cashierID),
          text: this.$t('generic.lang_kasse') + " " + cashierID
        };
      });
    }
  },

  data() {
    return {
      activeTab: 0,
      loading: false,
      errorDialogMessage: "",
      group_by: 1,
      name: this.$t('settings.kassendrucker'),
      address: "192.168.178.",
      serverAddress: "127.0.0.1",
      gateway: "epos",
      paperType: 80,
      cashierID: [],
      autoPrint: 0,
      useSSL: 0,
      openCashDrawer: 0,
      lineBreak: false,
      autoPaperCut: false,
      showItemPrices: false,
      showPLU: false,
      hideItemNames: false,
      coursesOnOneBon: false,
      duplicateOrderBonPrint: false,
      playBuzzer: false,
      cashierIDs: [],
      externalType:'server',
      usbField1:null,
      usbField2:null,
    }
  },

  mounted() {
    this.cashierID.push(this.api.auth.cashierID);
    this.getCashiers();
  },

  methods: {
    getCashiers() {
      this.loading = true;

      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
      }).finally(() => this.loading = false);
    },
    createPrinter() {
      this.loading = true;

      this.axios.post(ENDPOINTS.PRINTSETTINGS.PRINTER.CREATE, {
        type: this.activeTab + 1,
        name: this.name,
        gateway: this.gateway,
        paperType: this.paperType,
        cashierID: this.cashierID,
        address: this.address,
        serverAddress: this.serverAddress,
        autoPrint: this.autoPrint,
        useSSL: this.useSSL,
        openCashDrawer: this.openCashDrawer,
        lineBreak: this.lineBreak,
        autoPaperCut: this.autoPaperCut,
        showItemPrices: this.showItemPrices,
        showPLU: this.showPLU,
        hideItemNames: this.hideItemNames,
        coursesOnOneBon: this.coursesOnOneBon,
        duplicateOrderBonPrint: this.duplicateOrderBonPrint,
        playBuzzer: this.playBuzzer,
        group_by: this.group_by,
        externalType: this.externalType,
        usbField1: this.usbField1,
        usbField2: this.usbField2,

      }).then((res) => {
        if (res.data.success) {
          this.$router.push({
            name: "settings.printerSettings.printerManager"
          });
        } else {
          this.errorDialogMessage = this.$t('generic.lang_anErrorOccurredPLSTryAgain');
        }

        this.loading = false;
      }).catch(() => {
        this.errorDialogMessage = this.$t('generic.lang_anErrorOccurredPLSTryAgain');

        this.loading = false;
      })
    }
  }
}
</script>
